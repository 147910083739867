export default [
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Fatihah",
        "versesCount": 7,
        "translatedName": "The Opener",
        "slug": "al-fatihah"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Al-Baqarah",
        "versesCount": 286,
        "translatedName": "The Cow",
        "slug": "al-baqarah"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Ali 'Imran",
        "versesCount": 200,
        "translatedName": "Family of Imran",
        "slug": "ali-imran"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "An-Nisa",
        "versesCount": 176,
        "translatedName": "The Women",
        "slug": "an-nisa"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Al-Ma'idah",
        "versesCount": 120,
        "translatedName": "The Table Spread",
        "slug": "al-maidah"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-An'am",
        "versesCount": 165,
        "translatedName": "The Cattle",
        "slug": "al-anam"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-A'raf",
        "versesCount": 206,
        "translatedName": "The Heights",
        "slug": "al-araf"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Al-Anfal",
        "versesCount": 75,
        "translatedName": "The Spoils of War",
        "slug": "al-anfal"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "At-Tawbah",
        "versesCount": 129,
        "translatedName": "The Repentance",
        "slug": "at-tawbah"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Yunus",
        "versesCount": 109,
        "translatedName": "Jonah",
        "slug": "yunus"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Hud",
        "versesCount": 123,
        "translatedName": "Hud",
        "slug": "hud"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Yusuf",
        "versesCount": 111,
        "translatedName": "Joseph",
        "slug": "yusuf"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Ar-Ra'd",
        "versesCount": 43,
        "translatedName": "The Thunder",
        "slug": "ar-rad"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Ibrahim",
        "versesCount": 52,
        "translatedName": "Abraham",
        "slug": "ibrahim"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Hijr",
        "versesCount": 99,
        "translatedName": "The Rocky Tract",
        "slug": "al-hijr"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "An-Nahl",
        "versesCount": 128,
        "translatedName": "The Bee",
        "slug": "an-nahl"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Isra",
        "versesCount": 111,
        "translatedName": "The Night Journey",
        "slug": "al-isra"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Kahf",
        "versesCount": 110,
        "translatedName": "The Cave",
        "slug": "al-kahf"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Maryam",
        "versesCount": 98,
        "translatedName": "Mary",
        "slug": "maryam"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Taha",
        "versesCount": 135,
        "translatedName": "Ta-Ha",
        "slug": "taha"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Anbya",
        "versesCount": 112,
        "translatedName": "The Prophets",
        "slug": "al-anbya"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Al-Hajj",
        "versesCount": 78,
        "translatedName": "The Pilgrimage",
        "slug": "al-hajj"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Mu'minun",
        "versesCount": 118,
        "translatedName": "The Believers",
        "slug": "al-muminun"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "An-Nur",
        "versesCount": 64,
        "translatedName": "The Light",
        "slug": "an-nur"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Furqan",
        "versesCount": 77,
        "translatedName": "The Criterion",
        "slug": "al-furqan"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Ash-Shu'ara",
        "versesCount": 227,
        "translatedName": "The Poets",
        "slug": "ash-shuara"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "An-Naml",
        "versesCount": 93,
        "translatedName": "The Ant",
        "slug": "an-naml"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Qasas",
        "versesCount": 88,
        "translatedName": "The Stories",
        "slug": "al-qasas"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-'Ankabut",
        "versesCount": 69,
        "translatedName": "The Spider",
        "slug": "al-ankabut"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Ar-Rum",
        "versesCount": 60,
        "translatedName": "The Romans",
        "slug": "ar-rum"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Luqman",
        "versesCount": 34,
        "translatedName": "Luqman",
        "slug": "luqman"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "As-Sajdah",
        "versesCount": 30,
        "translatedName": "The Prostration",
        "slug": "as-sajdah"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Al-Ahzab",
        "versesCount": 73,
        "translatedName": "The Combined Forces",
        "slug": "al-ahzab"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Saba",
        "versesCount": 54,
        "translatedName": "Sheba",
        "slug": "saba"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Fatir",
        "versesCount": 45,
        "translatedName": "Originator",
        "slug": "fatir"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Ya-Sin",
        "versesCount": 83,
        "translatedName": "Ya Sin",
        "slug": "ya-sin"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "As-Saffat",
        "versesCount": 182,
        "translatedName": "Those who set the Ranks",
        "slug": "as-saffat"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Sad",
        "versesCount": 88,
        "translatedName": "The Letter \"Saad\"",
        "slug": "sad"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Az-Zumar",
        "versesCount": 75,
        "translatedName": "The Troops",
        "slug": "az-zumar"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Ghafir",
        "versesCount": 85,
        "translatedName": "The Forgiver",
        "slug": "ghafir"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Fussilat",
        "versesCount": 54,
        "translatedName": "Explained in Detail",
        "slug": "fussilat"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Ash-Shuraa",
        "versesCount": 53,
        "translatedName": "The Consultation",
        "slug": "ash-shuraa"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Az-Zukhruf",
        "versesCount": 89,
        "translatedName": "The Ornaments of Gold",
        "slug": "az-zukhruf"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Ad-Dukhan",
        "versesCount": 59,
        "translatedName": "The Smoke",
        "slug": "ad-dukhan"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Jathiyah",
        "versesCount": 37,
        "translatedName": "The Crouching",
        "slug": "al-jathiyah"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Ahqaf",
        "versesCount": 35,
        "translatedName": "The Wind-Curved Sandhills",
        "slug": "al-ahqaf"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Muhammad",
        "versesCount": 38,
        "translatedName": "Muhammad",
        "slug": "muhammad"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Al-Fath",
        "versesCount": 29,
        "translatedName": "The Victory",
        "slug": "al-fath"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Al-Hujurat",
        "versesCount": 18,
        "translatedName": "The Rooms",
        "slug": "al-hujurat"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Qaf",
        "versesCount": 45,
        "translatedName": "The Letter \"Qaf\"",
        "slug": "qaf"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Adh-Dhariyat",
        "versesCount": 60,
        "translatedName": "The Winnowing Winds",
        "slug": "adh-dhariyat"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "At-Tur",
        "versesCount": 49,
        "translatedName": "The Mount",
        "slug": "at-tur"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "An-Najm",
        "versesCount": 62,
        "translatedName": "The Star",
        "slug": "an-najm"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Qamar",
        "versesCount": 55,
        "translatedName": "The Moon",
        "slug": "al-qamar"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Ar-Rahman",
        "versesCount": 78,
        "translatedName": "The Beneficent",
        "slug": "ar-rahman"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Waqi'ah",
        "versesCount": 96,
        "translatedName": "The Inevitable",
        "slug": "al-waqiah"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Al-Hadid",
        "versesCount": 29,
        "translatedName": "The Iron",
        "slug": "al-hadid"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Al-Mujadila",
        "versesCount": 22,
        "translatedName": "The Pleading Woman",
        "slug": "al-mujadila"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Al-Hashr",
        "versesCount": 24,
        "translatedName": "The Exile",
        "slug": "al-hashr"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Al-Mumtahanah",
        "versesCount": 13,
        "translatedName": "She that is to be examined",
        "slug": "al-mumtahanah"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "As-Saf",
        "versesCount": 14,
        "translatedName": "The Ranks",
        "slug": "as-saf"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Al-Jumu'ah",
        "versesCount": 11,
        "translatedName": "The Congregation, Friday",
        "slug": "al-jumuah"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Al-Munafiqun",
        "versesCount": 11,
        "translatedName": "The Hypocrites",
        "slug": "al-munafiqun"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "At-Taghabun",
        "versesCount": 18,
        "translatedName": "The Mutual Disillusion",
        "slug": "at-taghabun"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "At-Talaq",
        "versesCount": 12,
        "translatedName": "The Divorce",
        "slug": "at-talaq"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "At-Tahrim",
        "versesCount": 12,
        "translatedName": "The Prohibition",
        "slug": "at-tahrim"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Mulk",
        "versesCount": 30,
        "translatedName": "The Sovereignty",
        "slug": "al-mulk"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Qalam",
        "versesCount": 52,
        "translatedName": "The Pen",
        "slug": "al-qalam"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Haqqah",
        "versesCount": 52,
        "translatedName": "The Reality",
        "slug": "al-haqqah"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Ma'arij",
        "versesCount": 44,
        "translatedName": "The Ascending Stairways",
        "slug": "al-maarij"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Nuh",
        "versesCount": 28,
        "translatedName": "Noah",
        "slug": "nuh"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Jinn",
        "versesCount": 28,
        "translatedName": "The Jinn",
        "slug": "al-jinn"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Muzzammil",
        "versesCount": 20,
        "translatedName": "The Enshrouded One",
        "slug": "al-muzzammil"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Muddaththir",
        "versesCount": 56,
        "translatedName": "The Cloaked One",
        "slug": "al-muddaththir"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Qiyamah",
        "versesCount": 40,
        "translatedName": "The Resurrection",
        "slug": "al-qiyamah"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Al-Insan",
        "versesCount": 31,
        "translatedName": "The Man",
        "slug": "al-insan"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Mursalat",
        "versesCount": 50,
        "translatedName": "The Emissaries",
        "slug": "al-mursalat"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "An-Naba",
        "versesCount": 40,
        "translatedName": "The Tidings",
        "slug": "an-naba"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "An-Nazi'at",
        "versesCount": 46,
        "translatedName": "Those who drag forth",
        "slug": "an-naziat"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "'Abasa",
        "versesCount": 42,
        "translatedName": "He Frowned",
        "slug": "abasa"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "At-Takwir",
        "versesCount": 29,
        "translatedName": "The Overthrowing",
        "slug": "at-takwir"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Infitar",
        "versesCount": 19,
        "translatedName": "The Cleaving",
        "slug": "al-infitar"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Mutaffifin",
        "versesCount": 36,
        "translatedName": "The Defrauding",
        "slug": "al-mutaffifin"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Inshiqaq",
        "versesCount": 25,
        "translatedName": "The Sundering",
        "slug": "al-inshiqaq"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Buruj",
        "versesCount": 22,
        "translatedName": "The Mansions of the Stars",
        "slug": "al-buruj"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "At-Tariq",
        "versesCount": 17,
        "translatedName": "The Nightcommer",
        "slug": "at-tariq"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-A'la",
        "versesCount": 19,
        "translatedName": "The Most High",
        "slug": "al-ala"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Ghashiyah",
        "versesCount": 26,
        "translatedName": "The Overwhelming",
        "slug": "al-ghashiyah"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Fajr",
        "versesCount": 30,
        "translatedName": "The Dawn",
        "slug": "al-fajr"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Balad",
        "versesCount": 20,
        "translatedName": "The City",
        "slug": "al-balad"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Ash-Shams",
        "versesCount": 15,
        "translatedName": "The Sun",
        "slug": "ash-shams"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Layl",
        "versesCount": 21,
        "translatedName": "The Night",
        "slug": "al-layl"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Ad-Duhaa",
        "versesCount": 11,
        "translatedName": "The Morning Hours",
        "slug": "ad-duhaa"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Ash-Sharh",
        "versesCount": 8,
        "translatedName": "The Relief",
        "slug": "ash-sharh"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "At-Tin",
        "versesCount": 8,
        "translatedName": "The Fig",
        "slug": "at-tin"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-'Alaq",
        "versesCount": 19,
        "translatedName": "The Clot",
        "slug": "al-alaq"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Qadr",
        "versesCount": 5,
        "translatedName": "The Power",
        "slug": "al-qadr"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Al-Bayyinah",
        "versesCount": 8,
        "translatedName": "The Clear Proof",
        "slug": "al-bayyinah"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "Az-Zalzalah",
        "versesCount": 8,
        "translatedName": "The Earthquake",
        "slug": "az-zalzalah"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-'Adiyat",
        "versesCount": 11,
        "translatedName": "The Courser",
        "slug": "al-adiyat"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Qari'ah",
        "versesCount": 11,
        "translatedName": "The Calamity",
        "slug": "al-qariah"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "At-Takathur",
        "versesCount": 8,
        "translatedName": "The Rivalry in world increase",
        "slug": "at-takathur"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-'Asr",
        "versesCount": 3,
        "translatedName": "The Declining Day",
        "slug": "al-asr"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Humazah",
        "versesCount": 9,
        "translatedName": "The Traducer",
        "slug": "al-humazah"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Fil",
        "versesCount": 5,
        "translatedName": "The Elephant",
        "slug": "al-fil"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Quraysh",
        "versesCount": 4,
        "translatedName": "Quraysh",
        "slug": "quraysh"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Ma'un",
        "versesCount": 7,
        "translatedName": "The Small kindnesses",
        "slug": "al-maun"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Kawthar",
        "versesCount": 3,
        "translatedName": "The Abundance",
        "slug": "al-kawthar"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Kafirun",
        "versesCount": 6,
        "translatedName": "The Disbelievers",
        "slug": "al-kafirun"
    },
    {
        "revelationPlace": "madinah",
        "transliteratedName": "An-Nasr",
        "versesCount": 3,
        "translatedName": "The Divine Support",
        "slug": "an-nasr"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Masad",
        "versesCount": 5,
        "translatedName": "The Palm Fiber",
        "slug": "al-masad"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Ikhlas",
        "versesCount": 4,
        "translatedName": "The Sincerity",
        "slug": "al-ikhlas"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "Al-Falaq",
        "versesCount": 5,
        "translatedName": "The Daybreak",
        "slug": "al-falaq"
    },
    {
        "revelationPlace": "makkah",
        "transliteratedName": "An-Nas",
        "versesCount": 6,
        "translatedName": "Mankind",
        "slug": "an-nas"
    }
]